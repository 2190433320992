import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-99f68a12"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header-container"
};
const _hoisted_2 = {
  class: "title-container"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "icon-container"
};
const _hoisted_5 = {
  class: "selection-container"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "body-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ReleaseNote = _resolveComponent("ReleaseNote");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-body", {
      hidden: !_ctx.showBetaHistory,
      show: _ctx.showBetaHistory
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("platform.refunds.betaUpdates")), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("i", {
    class: "ti-close",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
  })])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.featureNames, feature => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["selection", {
        active: feature === $data.betaFeatureToDisplay
      }]),
      key: feature,
      onClick: $event => $data.betaFeatureToDisplay = feature
    }, _toDisplayString(feature), 11, _hoisted_6);
  }), 128))]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.historyToDisplay, (note, index) => {
    return _openBlock(), _createBlock(_component_ReleaseNote, {
      key: index,
      note: note,
      position: $options.historyToDisplay.length - index,
      showFullReport: $options.historyToDisplay.length - index === $data.showFullReportAt,
      onToggleReport: $options.displayFullReport
    }, null, 8, ["note", "position", "showFullReport", "onToggleReport"]);
  }), 128))])], 2);
}